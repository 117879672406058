<template>
	<div class="item account" v-loading="!account">
		<el-popover placement="bottom-end" width="300" trigger="click" v-if="account">
            <avatar :name="account.user.name" />
			<div class="el-popover__content">
				<div class="el-popover__body el-popover__account">
					<div class="user-info">
                        <avatar :name="account.user.name" />
						<div>
							<div class="name">
								{{ account.user.name }}
							</div>
							<div class="role" v-if="account.user.role.role">
								{{ account.user.role.role.title }}
							</div>
						</div>
					</div>
					<div class="links">
						<router-link to="/account/profile">
							<icon icon="gauge" />
							Profil Bilgilerim
						</router-link>
						<a class="cursor-pointer" @click="handleLogout">
							<icon icon="power" />
							Cikis Yap!
						</a>
					</div>
				</div>
			</div>
		</el-popover>
	</div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: 'Account',
        computed: mapState(['account']),
        methods: {
            handleLogout() {

                localStorage.removeItem('access_token');

                return this.goRoute('/')
            }
        },
    }
</script>
