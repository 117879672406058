<template>
    <div v-loading="pageLoading">
        <div class="loading-container" v-if="form.id">
            <el-card class="custom-padding">
                <template slot="header">
                    <div class="crud-header">
                        <div class="title">
                            {{ form.name }}
                        </div>
                        <div class="actions">
                            <el-tooltip content="Ekle" placement="bottom">
                                <el-button class="flat dark blue" icon="el-icon-plus" @click="goCreatePage" />
                            </el-tooltip>

                            <el-tooltip content="Duzenle" placement="bottom">
                                <el-button class="flat dark green" icon="el-icon-edit" @click="userDialogVisible = true"/>
                            </el-tooltip>
                        </div>
                    </div>
                </template>
                <div class="box-list">
                    <div class="box-list-item box-list-item-media is-title">
                        <div class="box-list-item-media-item">
                            <div class="media">
                                <img src="@/resources/img/dummy.png" width="100px" height="100px" alt="avatar"
                                     v-if="!form.avatar">
                                <img :src="appConfig.s3Path + '/' + form.avatar" width="100px" height="100px"
                                     alt="avatar" v-if="form.avatar">
                            </div>
                            <div class="text">
                                <div class="name">
                                    <email :item="form.email"/>
                                </div>
                                <div class="name">
                                    <phone :item="form.phone"/>
                                </div>
                                <div class="name">
                                    <el-tooltip content="Dogum Tarihi" placement="right">
                                        <span>{{ form.created_at }}</span>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box-list-item">
                        <div class="name">Cinsiyet</div>
                        <div class="description">
                            {{ form.gender }}
                        </div>
                    </div>
                    <div class="box-list-item">
                        <div class="name">Egitim Durumu</div>
                        <div class="description">
                            {{ form.education }}
                        </div>
                    </div>
                    <div class="box-list-item">
                        <div class="name">Meslek</div>
                        <div class="description">
                            {{ form.job }}
                        </div>
                    </div>
                    <div class="box-list-item">
                        <div class="name">Dogum Tarihi</div>
                        <div class="description">
                            {{ form.birthday }}
                        </div>
                    </div>
                </div>
            </el-card>
            <Note :path="$route.fullPath" />
            <el-menu :default-active="activeTab" mode="horizontal" background-color="#545c64" text-color="#fff" active-text-color="#e85f79" @select="handleSelect" class="container-tab-menu">
                <el-menu-item index="1">Satis</el-menu-item>
                <el-menu-item index="2">Bildirim Ayarlari</el-menu-item>
                <el-menu-item index="3">Adresler</el-menu-item>
                <el-menu-item index="4">Sosyal Hesaplar</el-menu-item>
                <el-menu-item index="5">Cihazlar</el-menu-item>
                <el-menu-item index="6">Roller</el-menu-item>
                <el-menu-item index="7">Branslar</el-menu-item>
                <el-menu-item index="9">Aile</el-menu-item>
                <el-menu-item index="8">Ders Izleme Gecmisi</el-menu-item>
            </el-menu>

            <div v-show="activeTab === '1'">
                <el-row :gutter="24">
                    <el-col :span="16">
                        <Orders :id="$route.params.id" />
                        <OrderReturns :id="$route.params.id" />
                    </el-col>
                    <el-col :span="8">
                        <OrderDeliveries :id="$route.params.id" />
                    </el-col>
                </el-row>
            </div>

            <Notifications :id="$route.params.id" v-show="activeTab === '2'" />
            <Addresses :id="$route.params.id" v-show="activeTab === '3'" />
            <Socials :id="$route.params.id" v-show="activeTab === '4'" />
            <Devices :id="$route.params.id" v-show="activeTab === '5'" />
            <Roles :id="$route.params.id" v-show="activeTab === '6'" />
            <Branches :id="$route.params.id" v-show="activeTab === '7'" />
            <LessonMonitoring :id="$route.params.id" v-show="activeTab === '8'" />
            <Family :id="$route.params.id" v-show="activeTab === '9'" />

            <el-dialog title="Kullanici Bilgileri" :visible.sync="userDialogVisible" width="80%" append-to-body class="custom-padding">
                <el-card class="custom-padding no-shadow no-border" shadow="never">
                    <el-form :label-position="'top'" ref="form" :model="form">
                        <div class="card-sub-title">Genel Bilgiler</div>
                        <div class="padding-20">
                            <el-row :gutter="24">
                                <el-col :span="20">
                                    <el-form-item label="Kullanici Adi">
                                        <el-input v-model="form.name" placeholder="Kullanici Adi"/>
                                    </el-form-item>
                                    <el-form-item label="E-Posta Adresi / Ogrenci ise T.C. Kimlik Numarasi">
                                        <el-input v-model="form.email" placeholder="E-Posta Adresi / Ogrenci ise T.C. Kimlik Numarasi"/>
                                    </el-form-item>
                                    <el-form-item label="Telefon Numarasi">
                                        <el-input v-model="form.phone" placeholder="Telefon Numarasi" v-mask="'0 (###) ### ## ##'"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4">
                                    <el-form-item label="Avatar">
                                        <fs v-model="form.avatar"/>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>

                        <div class="card-sub-title">Sifre Bilgileri</div>
                        <div class="padding-20">
                            <el-form-item label="Sifre">
                                <el-input v-model="form.password" placeholder="Sifre"/>
                            </el-form-item>
                        </div>

                        <div class="card-sub-title">Hesap Dogrulama</div>
                        <div class="padding-20">
                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <el-form-item label="E-Posta Adresi Dogrulama Tarihi">
                                        <el-date-picker v-model="form.email_verified_at" type="datetime" placeholder="Tarih Seçiniz"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="Telefon Dogrulama Tarihi">
                                        <el-date-picker v-model="form.phone_verified_at" type="datetime" placeholder="Tarih Seçiniz"/>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>

                        <div class="card-sub-title">Kisisel Bilgileri</div>
                        <div class="padding-20">
                            <el-form-item label="Bağlı Olduğu Kullanıcı">
                                <user :container="form" model="parent_id" placeholder="Bağlı Olduğu Kullanıcı" />
                            </el-form-item>

                            <el-form-item label="Doğum Günü">
                                <el-date-picker v-model="form.birthday" format="d/M/yyyy" value-format="yyyy-MM-dd"
                                                type="date" placeholder="Tarih Seçiniz"/>
                            </el-form-item>

                            <el-form-item label="Cinsiyet">
                                <definitions alias="genders" :container="form" model="gender_definition_id"/>
                            </el-form-item>

                            <el-form-item label="Egitim Durumu">
                                <definitions alias="educations" :container="form" model="education_definition_id"/>
                            </el-form-item>

                            <el-form-item label="Meslek">
                                <definitions alias="jobs" :container="form" model="job_definition_id"/>
                            </el-form-item>
                        </div>
                    </el-form>
                </el-card>

                <template slot="footer">
                    <el-button @click="update" class="flat dark green large fluid">
                        Guncelle
                    </el-button>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import Note from "../../../Components/Note";
import Roles from "./Roles";
import Devices from "./Devices";
import Socials from "./Socials";
import Addresses from "./Addresses";
import Notifications from "./Notifications";
import Orders from "./Orders";
import OrderReturns from "./OrderReturns";
import OrderDeliveries from "./OrderDeliveries";
import LessonMonitoring from "./LessonMonitoring";
import Branches from "@/app/Pages/Crm/Users/Branch";
import Family from "@/app/Pages/Crm/Users/Family";

export default {
    name: 'CrudItem',
    components: {
        Family,
        Branches,
        LessonMonitoring,
        Notifications,
        Note,
        Addresses,
        Socials,
        Devices,
        Roles,
        Orders,
        OrderReturns,
        OrderDeliveries,
    },
    data() {
        return {
            pageLoading: false,
            activeTab: '1',
            form: {
                //
            },

            userDialogVisible: false,
        }
    },
    mounted() {
        this.show();
    },
    methods: {

        handleSelect(event) {
            this.activeTab = event
        },

        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink(), {
                    params: {
                        with: []
                    }
                })
                .then(response => {
                    this.form = response.body.data;

                    if (!this.lodash.size(this.form.meta)) {
                        //this.form.meta = {};
                    }

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Form update
         */
        update() {
            this.pageLoading = true;

            this.$http
                .put(this.$route.meta.apiPoint + '/' + this.$route.params.id, this.form)
                .then(response => {
                    this.pageLoading = false;

                    this.userDialogVisible = false;

                    this.show();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },
    }
}
</script>
